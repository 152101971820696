import restaurant from "./images/casa-plai.jpg";
import img_card from "./images/img1.jpg";
import imgheaderrestaurant from "./images/14.jpg";
import imgheader from "./images/img6.jpg";
import imgheaderm from "./images/img6.jpg";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/img4.jpg";
import imgheadermiinimb from "./images/img4.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Andrei",
        mireasa: "Ana",
        data: "26 Noiembrie 2022",
        data_confirmare: "16 noiembrie 2022",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "inviatiiweb@gmail.com", 
       tel: "+373 673 90 675",
       phone: "tel:+37367390675",
       viber: "viber://chat?number=%2B37367390675",
       whatsapp: "https://wa.me/+37367390675",
       messenger: "https://www.messenger.com/t/InvitatiiWeb",
       tel1: "+373xxx",
       phone1: "tel:+XXX",
       viber1: "viber://chat?number=%2BXX",
       whatsapp1: "https://wa.me/+XXX",
       messenger1: "https://www.messenger.com/t/xxx",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Casa Plai",
            data: "26 noiembrie 2022, sâmbătă, ora 18:00",
            adress: "Strada Ungheniului, Fălești",
            harta: "https://goo.gl/maps/cGj71p154Kbk7F5T9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10770.866898326185!2d27.7439664!3d47.5538418!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xedfbfd7cd9d0494a!2sCasa%20Plai!5e0!3m2!1sro!2s!4v1654158665647!5m2!1sro!2s"
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care vom celebra căsătoria nostră, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "NAȘII",
            nasii_nume: "Eugen & Natalia",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;